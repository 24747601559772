<template>
	<section class="content-container">
		<SectionTabs :tabs="tab_component" />
		<router-view />
	</section>
</template>

<script>
import SectionTabs from "@/components/Section/SectionTabs.vue";

export default {
	components: { SectionTabs },
	data() {
		return {
			title: "Transporte",
			tab_component: [
				{
					name: "Dashboard",
					route: "transporte-dashboard",
				},
				{
					name: "Mis tramos",
					route: "transporte-tramos",
				},
				{
					name: "Mis planificaciones",
					route: "transporte-planificaciones",
				},
				{
					name: "En curso",
					route: "transporte-en_curso",
				},
				{
					name: "Transportes",
					route: "transporte-transportes",
				},
				{
					name: "Inscripciones",
					route: "transporte-inscripciones-list",
				},
			],
		};
	},
	mounted() {
		setInterval(() => {
			this.checkIfUserIsLogged();
		}, 100);
	},
};
</script>